<template>

<v-card :loading="loading" class="mx-auto my-15" max-width="374" max-height="" elevation="4">
  <input style="display:none;" @keyup.enter="Submit">
  <template slot="progress">
    <v-progress-linear color="blue-grey darken-4" height="5" indeterminate>
    </v-progress-linear>
  </template>
  <!--      
  <div class="row" style="padding-top:10px; padding-bottom:10px; ">
  <span style="padding-left:35px;" class="col-3">
  <v-img src="require('@/assets/z.svg')" width="40"/> </span>
  </div>
  <v-divider class="mx-5 my-1"></v-divider>
  -->
  <div style="padding-top:10px; ">
  <v-img class="mx-auto my-3" :src="require('@/assets/z.svg')" width="50"/> 
  </div>
      <v-col style="padding:0;"
      class="text-center"
      cols="12">
      <h3> Smart Pay </h3>
      <v-card-text style="padding:0; padding-bottom:10px;">
      Get instant credit to Trading Account
      </v-card-text>
      </v-col>
      
  <v-divider class="mx-5 my-1"></v-divider>

   
  <v-card-text v-on:keyup.enter="Submit">
      
        <v-text-field class="mx-4" v-model="code" label="Client Code " :rules="userRules" validate required @keyup="uppercase"  id="clientcode" :error-messages="userErrors" autocomplete="off"></v-text-field>    
    
  </v-card-text>
  <v-card-text>
      <v-row align="center" class="mx-0 pb-1">
          <div class="grey--text ms-4"></div>
      </v-row>
      
        <v-text-field class="mx-4" v-model="pan_number" label="PAN Number " :rules="panRules" validate required @keyup="uppercasepan" @input="$v.pan_number.$touch()" counter maxlength="10" id="pan_number" :error-messages="panErrors" v-on:keyup.enter="Submit"></v-text-field>    
      
  </v-card-text>
  
  <v-card-actions>
    <v-row justify="end" class="py-5 mx-3">
      <v-btn class="mx-1" color="blue-grey darken-4" text v-on:click="Submit">
        Submit
      </v-btn>
    </v-row>
  </v-card-actions>

  <!--v-footer padless>
    <v-col
      class="text-center"
      cols="12">
      <v-card-text style="padding:0;">
      Other mode of payment transfer <br> ECMS | NETF | RTGS | IMPS
      </v-card-text>
      </v-col>
  </v-footer -->
  <v-footer padless>
  <v-col class="text-center" cols="12">
        <!--<a :href="`tel:+919677945335`" class="mx-4" style="text-decoration: none;" icon>
        
              <v-icon size="24px">
                mdi-help-box
              </v-icon>
        
        </a>-->
        <a :href="`tel:=+919380108010`" class="mx-4" style="text-decoration: none;" icon>
              <v-icon size="24px">
                mdi-phone
              </v-icon>
        </a>
        <a :href="`mailto:payin@zebuetrade.com`" class="mx-4" style="text-decoration: none;" icon>
              <v-icon size="24px">
                mdi-email
              </v-icon>
        </a>
    </v-col>
</v-footer>

</v-card>

</template>
<script>
            history.pushState(null, null, document.URL);
window.addEventListener('popstate', function () {
    history.pushState(null, null, document.URL);
});
</script>
<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate'
import { required, maxLength,  } from 'vuelidate/lib/validators'
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';

export default {mixins: [validationMixin],
    validations: {
      pan_number: { required, maxLength: maxLength(10) },
      code: { required,  maxLength: maxLength(10)},
            select: { required },
       },
    data: function() {
       return {
        code: null,
        pan_number:null,
        loading: false,
        selection: 1,
        zlogo: require('@/assets/logo.svg'),
        resp: '',
        valid: true,
        clientcode_validate : null,
        pan_validate : null,
        userRules:[
          v => !!v || 'User ID is required',
        ],
        panRules:[
          v => !!v || 'PAN number is required',
        ],
        icons: [
        'mdi-help-box',
        'mdi-phone',
        'mdi-email',
      ],
    };
    
},
computed:{
  panErrors () {
        const errors = []
        if (!this.$v.pan_number.$dirty) return errors
        var pval = this.pan_validate;
        //console.log("Pan V :",pval)
        if (pval == 1)
        {
            !this.$v.pan_number.validate && errors.push('Invalid PAN NUMBER')   
        }
        !this.$v.pan_number.required && errors.push('Please Enter PAN Number.')
        return errors
      },
      
       userErrors () {
        const errors = []
        //console.log("Ccode v :",this.clientcode_validate)
        var cval =this.clientcode_validate;
        if (!this.$v.code.$dirty) return errors
        if (cval == 1){
             !this.$v.code.validate && errors.push('Client id is not available')     
        }
        !this.$v.code.required && errors.push('Please Enter User ID.');
        //console.log("userErrors : ", errors);
        return errors
        
      },
},
methods: {
    validate (){
        this.$$refs.form.validate();
    },
    uppercase() {
        this.code = this.code.toUpperCase();
    },
    uppercasepan() {
        this.pan_number = this.pan_number.toUpperCase();
    },
    support(data){
        //console.log("support :", data)
    },
    
    Submit () {
       this.$v.$touch();
        if(this.code && this.pan_number){
        this.loading = true;
       
        
        var val = this
        //console.warn("Client Code:",this.code);

        //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        axios.post('https://zapi.zebull.in/client_check?code='+this.code)
        // axios.post('https://bo.zebull.in/staticdata/wsdl/JSON_searchpan_v1.cfm?USERNAME=api&password=api@54321&search_on=client_id&value='+this.code)

        .then(function (response) {
            //console.log(response.data['client_check']);
            //this.resp = response.data;
            if(response.data['client_check'].length <=29 && typeof(response.data['client_check']) == 'string'){ 
                
                val.clientcode_validate = 1;
                val.loading = false;
                
            }
            else{
                val.clientcode_validate =0;
                //console.log('Resp:',response.data['client_check']['DATA'][0][1]);
                var pan = response.data['client_check']['DATA'][0][1];
                if (val.pan_number == pan){
                  val.pan_validate=0;
                  //val.$router.push({ path: '/fund', query: { id: val.code } });
                  var prop = val.code;
                  //console.log('Im Routing');
                  val.$router.replace({name:'About',params:{prop}});
                }
                else{
                  val.pan_validate=1;
                  val.loading =false;
                  // console.log(val)
                }
            }
        })
        .catch(function (error) {
          alert(error);
        });
      
        
     }
    }
    },
}
</script>
